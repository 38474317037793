<template>
  <v-sheet class="customer" style="height: calc(100vh - 140px)">
    <v-row>
     
      <v-col md="12" class="py-0">
        <!-- <pre>{{ productList }}</pre> -->
       
        <v-row>
          <!-- Drag and drop oprations -->
          <v-col md="12" class="pb-0">
            <!-- {{ selectstatus }} -->
            <v-row>
              <v-layout class="">
                <v-col md="3" class="py-0" style="overflow: auto;">
                  <div class="d-flex justify-center pb-1 pt-3"  >
                    <VCard
                      @click="statusSelect(all)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #90caf9; width: 500px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="blue lighten-5"
                        variant="tonal"
                        class="me-3"
                      >
                        <span class="text-h4 blue--text font-weight-bold">
                          {{ initial_paids+paids+final_pendings+pendings+initial_draft }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">All</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 blue--text" style="font-weight: 600">
                          {{ formatMoney(all_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    </div>
                </v-col>
                <v-col md="3" class="py-0" style="overflow: auto;">
                  <div class="d-flex justify-center pb-1 pt-3"  >
                    <VCard 
                      @click="statusSelect(10)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid cyan; width: 200px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="cyan lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 cyan--text font-weight-bold">
                          {{ initial_draft }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Draft</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 cyan--text mb-0" style="font-weight: 600">
                          {{ formatMoney(initial_draft_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    </div>
                </v-col>
                <!-- <v-col md="3" class="py-0" style="overflow: auto;">
                  <div class="d-flex justify-center pb-1 pt-3"  >
                    <VCard 
                      @click="statusSelect(4)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid cyan; width: 200px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="cyan"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 white--text font-weight-bold">
                          {{ initial_draft }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Approve</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 cyan--text mb-0" style="font-weight: 600">
                          {{ formatMoney(initial_draft_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    </div>
                </v-col> -->


                <v-col md="3" class="py-0" style="overflow: auto;">
                  <div class="d-flex justify-center pb-1 pt-3"  >
                    <VCard 
                      @click="statusSelect(4)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid green; width: 500px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="green lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 green--text font-weight-bold">
                          {{ initial_paids+paids }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Paid</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 green--text mb-0" style="font-weight: 600">
                          {{ formatMoney(paids_amount +initial_paids_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    </div>
                </v-col>

                <v-col md="3" class="py-0" style="overflow: auto;">
                  <div class="d-flex justify-center pb-1 pt-3" > 
                    <VCard 
                      @click="statusSelect(5)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid purple; width: 500px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="purple lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 purple--text font-weight-bold">
                          {{ final_pendings+pendings }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Unpaid</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 purple--text mb-0" style="font-weight: 600">
                          {{ formatMoney(final_pendings_amount +pendings_amount) }}
                        </h4>
                      </div>
                    </VCard>
                  </div>
                </v-col>
              </v-layout>
            </v-row>
            <v-row>
              <v-layout class="">
                <v-col md="12" class="py-0" style="overflow: auto;">
                  <div class="d-flex justify-center pb-1 pt-3"> 
                    <!-- <VCard
                      @click="statusSelect(all)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #90caf9; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="blue lighten-5"
                        variant="tonal"
                        class="me-3"
                      >
                        <span class="text-h4 blue--text font-weight-bold">
                          {{ totalRows }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">All</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 blue--text" style="font-weight: 600">
                          {{ formatMoney(all_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    <VCard 
                      @click="statusSelect(4)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #00BCD4; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="cyan lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 cyan--text font-weight-bold">
                          {{ initial_paids+paids }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Paid</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 cyan--text mb-0" style="font-weight: 600">
                          {{ formatMoney(paids_amount +initial_paids_amount) }}
                        </h4>
                      </div>
                    </VCard> -->
                     <VCard v-if="selectstatus==4 ||selectstatus==3 ||selectstatus==8" 
                      @click="statusSelect(initial_paid)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #56aef7; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="indigo lighten-5"
                        variant="tonal"
                        class="me-3"
                      >
                        <span class="text-h4 indigo--text darken-2 font-weight-bold">
                          {{ initial_paids }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Initial Paid</h3>
                      <VSpacer />
                      <div>
                        <h4
                          class="text-h4 pe-3 indigo--text darken-2 mb-0"
                          style="font-weight: 600"
                        >
                          {{ formatMoney(initial_paids_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    <VCard v-if="selectstatus==4 ||selectstatus==3 ||selectstatus==8" 
                      @click="statusSelect(8)"
                      class="d-flex align-center me-0 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #95c999; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="green lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 green--text font-weight-bold">
                          {{ paids }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Final Paid</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 green--text mb-0" style="font-weight: 600">
                          {{ formatMoney(paids_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    <!-- <VCard 
                      @click="statusSelect(5)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid purple; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="purple lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 purple--text font-weight-bold">
                          {{ final_pendings+initial_pending }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Unpaid</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 purple--text mb-0" style="font-weight: 600">
                          {{ formatMoney(final_pendings_amount +pendings_amount) }}
                        </h4>
                      </div>
                    </VCard> -->
                    <VCard v-if="selectstatus==5 || selectstatus==2  || selectstatus==6"
                      @click="statusSelect(initial_pending)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #e57373; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="red lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 red--text font-weight-bold">
                          {{ pendings }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Initial Pending</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 red--text mb-0" style="font-weight: 600">
                          {{ formatMoney(pendings_amount) }}
                        </h4>
                      </div>
                    </VCard>
                   
                    
                    <VCard v-if="selectstatus==5 || selectstatus==2 || selectstatus==6"
                      @click="statusSelect(final_pending)"
                      class="d-flex align-center me-3 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid orange; width: 330px"
                    >
                      <VAvatar
                        :size="55"
                        rounded
                        color="orange lighten-5"
                        variant="tonal"
                        class="me-2"
                      >
                        <span class="text-h4 orange--text font-weight-bold">
                          {{ final_pendings }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Final Pending</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 orange--text mb-0" style="font-weight: 600">
                          {{ formatMoney(final_pendings_amount) }}
                        </h4>
                      </div>
                    </VCard>
                  </div>
                </v-col>
              </v-layout>
            </v-row>
            <!-- 2nd row Add Product button -->
            <div class="d-flex flex-row align-center justify-content-between py-2">
              <PageHeaderCounts
                moduleType="purchaseorder"
                :dataLoading="dataLoading"
                :stat="selectstatus"
                :paids="paids"
                :initial_paids="initial_paids"
                :pendings="pendings"
                :final_pendings="final_pendings"
                :status-list="status_list"
                :initial_draft="initial_draft"
                allkey="all_purchase"
                countkey="status_count"
                v-on:saveAddress="updateAddressPerson"
              ></PageHeaderCounts>
              <v-col md="3" class="py-0 pr-0 mr-2">
                <!-- kk - {{ range && Array.isArray(range) && range[0] }} -->
                <DateRangePicker
                  hide-details
                  :hideTopMargin="true"
                  v-on:clear="searchranges()"
                  :minDate="range && Array.isArray(range) && range[0]"
                  v-on:change="searchrange()"
                  v-model="range"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="item-sale-duration"
                  placeholder="Date Range"
                  clearable
                ></DateRangePicker>
              </v-col>
              <div class="d-flex">
                <div class="mr-3">
                  <AutoCompleteInput
                    hide-details
                    :items.sync="statusListing"
                    item-text="status"
                    item-value="id"
                    id="segment"
                    style="width: 150px"
                    placeholder="Select Status"
                    v-model="status_filter"
                    v-on:change="findstatus()"
                  ></AutoCompleteInput>
                  <!--    <SelectInput hide-details :hideTopMargin="true" :items="supplierLists" id="segment"
                                        item-text="company_name" item-value="id" v-model="supplier_filter"
                                        v-on:change="searchsupplier()" style=" width: 150px;" placeholder="Select supplier">
                                    </SelectInput> -->
                </div>
                <div class="mr-3">
                  <AutoCompleteInput
                    hide-details
                    :items.sync="supplierLists"
                    item-text="company_name"
                    item-value="id"
                    id="segment"
                    style="width: 150px"
                    placeholder="Select supplier"
                    v-model="supplier_filter"
                    v-on:change="searchsupplier()"
                  ></AutoCompleteInput>
                  <!--    <SelectInput hide-details :hideTopMargin="true" :items="supplierLists" id="segment"
                                        item-text="company_name" item-value="id" v-model="supplier_filter"
                                        v-on:change="searchsupplier()" style=" width: 150px;" placeholder="Select supplier">
                                    </SelectInput> -->
                </div>
                <div class="mr-3"></div>
                <div class="mr-3">
                  <AutoCompleteInput
                    hide-details
                    :hideTopMargin="true"
                    :items="paymentModeList"
                    id="segment"
                    style="width: 150px"
                    item-value="value"
                    v-model="paymentby"
                    v-on:change="paymentBy()"
                    placeholder="Payment By"
                  ></AutoCompleteInput>
                </div>
                <v-btn
                  color="blue darken-4"
                  depressed
                  tile
                  class="mr-2 white--text"
                  :to="{ name: 'purchase-order-create', query: { t: new Date().getTime() } }"
                >
                  New
                </v-btn>
                <v-menu
                  offset-y
                  left
                  transition="slide-y-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="blue darken-4"
                          depressed
                          tile
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          class="white--text"
                        >
                          <v-icon>edit_note</v-icon>
                        </v-btn>
                      </template>
                      <span>Columns arrangement and visibility</span>
                    </v-tooltip>
                  </template>
                  <v-list nav dense>
                    <v-list-item
                      draggable
                      v-on:dragstart="dragStart(index)"
                      v-on:dragend="dragEnd"
                      v-on:dragover="dragOver(index)"
                      v-on:mousedown="mouseEvent('mousedown')"
                      v-on:mouseup="mouseEvent('mouseup')"
                      v-for="(item, index) in defaultColDefs"
                      :key="item.field + '_' + index"
                      class="flex-column cursor-move"
                    >
                      <div class="d-flex flex-row align-center">
                        <v-checkbox :ripple="false" hide-details color="#0d47a1" v-model="defaultColShow"
                        v-bind:value="item.field" :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()" class="m-0"></v-checkbox>
                    <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                        <v-icon>drag_indicator</v-icon>
                      </div>
                      <v-divider
                        v-if="index < customThead.length - 1"
                        class="mt-2 mb-0"
                        style="width: 100%"
                      ></v-divider>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" class="pb-0 pt-1">
        <v-simple-table fixed-header class="bt-table product-simple-table">
          <template v-slot:default>
            <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
              <tr>
                <th v-for="(product_h, index) in customThead" :key="'$_' + product_h.title + '_' + index + 1">
                  <span v-if="product_h.field === '#'">
                    <v-checkbox v-model="productTableSelectAll" v-on:change="checkAll" hide-details color="#0d47a1"
                      class="ma-0 pa-0"></v-checkbox>
                  </span>
                  <span v-else>
                    {{ product_h.headerName }}
                  </span>
                </th>
              </tr>
            </thead>
            <!-- table titles -->

            <!-- table body -->

            <tbody v-if="!pageLoading">
              <template v-if="productList?.length">
                <tr
                  v-for="(row, bkey) in productList"
                  :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="product-listing-tr"
                  :key="'k_' + bkey"  link
                    v-on:click="routeToDetail(row.uuid)"
                >
                  <td
                    style="border-right: none !important"
                    link
                    v-on:click="routeToDetail(row.uuid)"
                    v-for="(data, idx) in customThead"
                    :key="data.title + '_' + idx + '_' + idx * 5"
                    :width="
                      data.field == 'actions'
                        ? '60px'
                        : '' || data.field == '#'
                        ? '20px'
                        : ''
                    "
                  >
                    <ValueTemplate
                      v-if="data.field == 'uom_primary'"
                      :image="data.field === 'profile_logo'"
                      :title="data.title"
                      :decoration="data.decoration"
                      :value="(row, data)"
                    >
                      <template #value>
                        <div
                          v-if="
                            data.field === 'uom_primary' ||
                            data.field === 'uom_secondary' ||
                            data.field === 'reorder'
                          "
                        >
                          <v-chip
                            :class="'mr-0 pa-0 px-2' + ' ' + data.decoration.customClass"
                            :outlined="data.decoration.outlined"
                            :color="data.decoration.bgColor"
                            :text-color="data.decoration.textColor"
                            small
                          >
                            <span v-if="data.field === 'reorder'">{{ row["reorder"] }}</span>
                            <span v-else>{{ row["unit_secondary"] }}</span>
                          </v-chip>
                          {{ data }}
                          <span v-if="data.field === 'uom_secondary'">
                            {{ row.primary_sales_uom }} </span
                          >&nbsp;
                          <span v-if="data.field === 'Reorder'">{{ row["uom_primary"] }}</span>
                          <span v-else>{{ row[data.key] }}</span>
                        </div>
                      </template>
                    </ValueTemplate>

                    <div v-else-if="data.field === '#'" @click.stop class="d-flex align-center">
                      <v-checkbox
                        v-model="row['status']"
                        hide-details
                        color="#0d47a1"
                        class="ma-0 pa-0"
                      ></v-checkbox>
                    </div>

                    <!-- Action buttons -->

                    <div
                      v-else-if="data.field === 'action'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <!-- order icon -->
                      <!-- <v-tooltip top content-class="custom-top-tooltip">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn depressed fab dark x-small color="blue-grey lighten-1"
                                                        v-bind="attrs" v-on="on" @click.stop.prevent="addOrder(row)">
                                                        <v-icon>mdi-cart-arrow-up</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Order</span>
                                            </v-tooltip> -->

                      <!-- edit icon -->
                      <v-tooltip top content-class="custom-top-tooltip" v-if="row.po_status=='Draft'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="mx-2"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="routeToUpdate(row.uuid)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>

                      <!-- delete key --> 
                      <!-- {{row}} -->
                      <v-tooltip top content-class="custom-top-tooltip" v-if="row.po_status=='Draft'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="deleteConfirm(row)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <p v-if="row.po_status!='Draft'">No Action</p>
                      
                     
                    </div>

                    <div
                      v-else-if="data.field === 'activated'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <v-switch
                        v-model="row[data.field]"
                        class="mt-0"
                        v-on:click.prevent.stop="() => activeInactive(row[data.field], row.id)"
                        color="green"
                      ></v-switch>
                    </div>

                    <div
                      v-else-if="data.field === 'po'"
                      style="border-right: none !important"
                      width="100"
                    >
                    
                      <v-chip small outlined label color="blue">{{ row.purchase_order}}</v-chip>
                    </div>
                    <div
                      v-else-if="data.field === 'details'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div>
                        Ref: <span v-if="row.details" style="text-transform: uppercase;">{{ row.details }}</span>
                        <span v-else>No Ref</span>
                      </div>
                      <div>
                        Delivery: <span>{{ formatedateTime(row.delivery_date) }}</span>
                      </div>
                    </div>
                    <div
                      v-else-if="data.field === 'contact_person'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                        <b style="text-transform: uppercase;">{{ row.supplier_contact_details }}</b>
                      </div>
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                       
                         <span v-if="row.contact_email">{{
                          row.contact_email
                        }}</span>
                         <span v-else>No Email</span>
                      </div>
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                        <span v-if="row.contact_phone">{{
                          row.contact_phone
                        }}</span>
                        <span v-else>No Phone Number</span>
                      </div>
                    </div>
                    <div
                      v-else-if="data.field === 'supplier'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div>
                        <v-icon color="disabled" size="18" class="me-1"
                          >mdi-home-city-outline</v-icon
                        >
                        <span
                          ><b>{{ row.supplier }}</b></span
                        >
                      </div>
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                        <b style="text-transform: uppercase;">{{ row.supplier_contact_details }}</b>
                      </div>
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                        <span v-if="row.email" >{{
                          row.email
                        }}</span>
                         <span v-else>No Email</span>
                      </div>
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                        <span v-if="row.phone" >{{ row.phone }}</span>
                        <span v-else >No Phone Number</span>
                      </div>
                    </div>
                    <div
                      v-else-if="data.field === 'amount'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div>
                        {{formatMoney(row.amount) }}
                        <!-- <ValueTemplate class="font-weight-bold" v-model="row.amount" title="Amount">
                        </ValueTemplate>  -->
                        <div>

                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="data.field === 'payment_mode'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <v-chip v-if="row.payment_mode" small outlined label color="success">{{
                        row.payment_mode
                      }}</v-chip>
                      <div v-else-if="row.bill && !row.payment_mode" class="simple-table-text">NA</div>
                    
                      <div v-else class="simple-table-text">No Payment</div>
                    </div>
                    <div
                      v-else-if="data.key === 'secondary'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <v-chip v-if="row.payment_two" small outlined label color="success">{{
                        row.payment_two
                      }}</v-chip>
                      <div v-else class="simple-table-text">No Payment</div>
                    </div>
                    <div
                      v-else-if="data.field === 'po_status'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <v-chip
                        v-if="!row.bill && row.po_status == 'Pending'"
                        small
                        outlined
                        label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'red'"
                        >Initial Pending</v-chip
                      >
                      <v-chip
                        v-if="row.po_status == 'Draft'"
                        small
                        outlined
                        label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'cyan'"
                        >Draft</v-chip
                      >
                      <v-chip
                        v-else-if="!row.bill && row.po_status == 'Initial Paid'"
                        small
                        outlined
                        label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'indigo'"
                        >Initial Paid</v-chip
                      >
                      <v-chip
                        v-else-if="row.bill && row.po_status == 'Initial Paid'"
                        small
                        outlined
                        label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'indigo'"
                        >Initial Paid</v-chip
                      >
                      <v-chip
                        v-else-if="row.bill && row.po_status == 'Pending'"
                        small
                        outlined
                        label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'orange'"
                        >Final Pending</v-chip
                      >
                      <v-chip
                        v-else-if="row.bill && row.po_status == 'Paid'"
                        small
                        outlined
                        label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'green'"
                        >Final Paid</v-chip
                      >
                    </div>
                    <div
                      v-else-if="data.field === 'bill'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <v-chip v-if="row.bill" small outlined label color="success">{{
                        row.bill
                      }}</v-chip>
                      <div v-else class="simple-table-text">No Billed</div>
                    </div>
                    <div
                      v-else-if="data.field === 'added_at'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div>
                        <v-chip small outlined label color="blue">{{ row.created_by }}</v-chip>
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-calendar</v-icon>
                          {{ formatedateTime(row.created_at) }}
                          <VTooltip activator="parent"> Created date </VTooltip>
                        </div>
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-clock</v-icon>
                          {{ formateTime(row.created_at) }}
                          <VTooltip activator="parent"> Created Time </VTooltip>
                        </div>
                      </div>
                    </div>

                    <div
                      v-else-if="data.field === 'updated_at'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div>
                        <!-- <v-chip small outlined label color="blue"></v-chip> -->
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-calendar</v-icon>
                          {{ formatedateTime(row.last_modified_at) }}
                          <VTooltip activator="parent"> Created date </VTooltip>
                        </div>
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-clock</v-icon>
                          {{ formateTime(row.last_modified_at) }}
                          <VTooltip activator="parent"> Created Time </VTooltip>
                        </div>
                      </div>
                    </div>

                    <!-- Name -->
                    <div v-else>
                      <div
                        v-for="(value, key, inddx) in row[data.key]"
                        :key="'$_' + value + '_' + inddx + '_' + key"
                      >
                        <span style="font-weight: 700">{{ key.toUpperCase() }}&nbsp;</span>
                        <ValueTemplate
                          v-model="row['name'][key]"
                          :title="`Name (${data.key.toUpperCase()})`"
                        />
                      </div>
                      <div class="mt-2">
                        {{ row["name_en"] }}
                        {{ row["name_cn"] }}
                        {{ row["name_my"] }}
                        ({{
                          row["unit_description_psu"] +
                          " " +
                          row["primary_sales_uom"] +
                          " " +
                          "X" +
                          " " +
                          row["unit_value"] +
                          " " +
                          "in" +
                          " " +
                          1 +
                          " " +
                          row["secondary_purchase_uom"]
                        }})
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="11">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no purchase Order at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>

            <tfoot v-else>
              <tr v-for="idr in 11" :key="idr">
                <td v-for="idk in 11" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>

        <v-layout v-if="productList?.length" class="light-border-top product-table-bottom mt-4">
          <v-flex md6 align-self-center>
            <label class="btx-label p-4">
              Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
            >
          </v-flex>
          <!-- {{ totalRows }} -->
          <v-flex md6 align-self-center>
            <v-pagination
              v-model="currentPage"
              :length="pageLimit"
              :total-visible="5"
              color="blue darken-4"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <DeleteTemplate
          type="purchase-order"
          :delete-text="deleteText"
          :delete-dialog="deleteDialog"
          :delete-endpoint="deleteEndpoint"
          v-on:close="deleteDialog = false"
          v-on:delete:success="getItems()"
        >
        </DeleteTemplate>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { PATCH, QUERY,POST } from "@/core/services/store/request.module";
import { GET_PO, GET_PAYMENT } from "@/core/lib/pos.lib";
import moment from "moment-timezone";
import PageHeaderCounts from "@/view/components/PageHeaderCounts.vue";
/* import SelectInput from "@/view/components/SelectInput"; */
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ValueTemplate from "@/view/components/ValueTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { All_PRPDUCTS } from "@/core/lib/product.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DateRangePicker from "@/view/components/DateRangePicker";
import { formatMoney } from "accounting-js";
import { SearchEventBus } from "@/core/lib/search.lib";

import { filter, map } from "lodash";


export default {
  name: "purchase-listing",
  title: "Listing purchase",
  data() {
    return {
      selectstatus: 1,
      defaultColDefs: [],
      customThead: [],
      productList: [],
      status_filter:null,
      searchEnabled: false,
      totalRows: 0,
      stauss: 1,
      range: null,
      isDisabled:true,
      searchParam: null,
      total: 0,
      all: 1,
      initial_pending: 2,
      final_pending: 6,
      final_paid: 5,
      initial_paid: 3,
      supplier_filter: null,
      paymentby: null,
      checkVal:true,
      bill: null,
      all_amount: 0,
      initial_paids_amount: 0,
      final_pendings_amount: 0,
      initial_draft:0,
      initial_draft_amount:0,
      paids_amount: 0,
      totalPages: 1,
      pendings_amount: 0,
      final_pendings: 0,
      paymentModeList: [],
      initial_paids: 0,
      supplierLists: [],
      pendings: 0,
      paids: 0,
      status_list: [],
      statusListing:[
      {
          id: 1,
          status: "Paid",
          value: 1,
        },
        {
          id: 2,
          status: "Unpaid",
          value: 2,
        }
     


      ],


      products: [
        {
          id: 1,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/4/47/Hamburger_%28black_bg%29.jpg",
          code: "ABC123",
          name: {
            en: "English",
            cn: "Chinese",
          },
          cat: "disposable",
          quantity: 12,
          reorder: 2,
          uom_primary: "CAN",
          uom_secondary: "CTN",
          unit_primary: "300ml",
          unit_secondary: 12,
        },
        {
          id: 2,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "DEF456",
          name: {
            en: "English 2",
            cn: "Chinese 2",
            my: "Myanmar 2",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 10,
          reorder: 1,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 3,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 4,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 5,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 6,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 7,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 8,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 9,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
      ],
      productTableHeading: [
      //  {
        //  title: "#",
         // key: "#",
         // status: true,
         // decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        //},
        {
          title: "Actions",
          key: "actions",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "PO#",
          key: "purchase_order",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Details",
          key: "detail",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "blue darken-2",
            customClass: "blue darken-2",
            outlined: true,
          },
        },
        {
          title: "Supplier",
          key: "supplier",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "blue darken-2",
            customClass: "blue darken-2",
            outlined: true,
          },
        },
        {
          title: "Contact Person",
          key: "contact_person",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Amount",
          key: "status",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#666666",
            textColor: "#000",
            customClass: "",
            outlined: true,
          },
        },
        {
          title: "Primary Mode",
          key: "primary",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#5cb85c",
            textColor: "#fff",
            customClass: "",
          },
        },
        // {
        //     title: "Secondary Payment Modes",
        //     key: "secondary",
        //     status: true,
        //     decoration: {
        //         is: true,
        //         type: "chip",
        //         bgColor: "#e64a4a",
        //         textColor: "#fff",
        //         customClass: "",
        //     },
        // },
        {
          title: "PO Status",
          key: "po_status",

          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "#fff",
            customClass: "blue darken-2",
          },
        },
        {
          title: "Bill",
          key: "bill",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "#fff",
            customClass: "blue darken-2",
          },
        },
        {
          title: "Created At",
          key: "created_At",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "#fff",
            customClass: "blue darken-2",
          },
        },
        {
          title: "Last Modified At",
          key: "modified_at",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "#fff",
            customClass: "blue darken-2",
          },
        },
      ],
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      dataLoading: false,
      pageLimit: 10,
      showingFrom: 1,
      showingTo: 10,
      currentPage: 1,
      dragOverIndexForProductTableColumns: null,
      dragStartIndexForProdctTableColumns: null,
      productTableSelectAll: false,
    };
  },
  watch: {
    currentPage() {
      this.getItems();
    },

    productTableSelectAll(value) {
      this.products.map((_value) => (_value.status = value));
    },
  },
  methods: {
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getItems();
          });
      });
    },
    findstatus(){
this.getItems();

console.log(this.status_filter,'status_filter')
    },
    updateAddressPerson(param) {
      this.selectstatus = param;
      this.getItems();
    },
    statusSelect(data) {
      this.selectstatus = data;
      this.getItems();
    },
    searchrange() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.date = this.range;
            const date1 = new Date(this.range[0]);
                    const date2 = new Date(this.range[1]);

        if(date1 >  date2 && date1 !=  date2 ){        
          this.$store.commit(SET_ERROR, [
            { model: true, message: "End Date must be after Start Date" },


          ]);
          return false;
               } else {
         this.getItems();
        


        }
        // this.getItems();
      }
    }, searchranges() {
      if (this.range?.length == 2) {

        console.log(this.range, "range");
        this.range = null;
         this.getItems();
      }
    },
    paymentBy() {
      this.paymentbys = this.paymentby;
      this.getItems();
    },
    searchsupplier() {
      this.supplier_id = this.supplier_filter;

      this.getItems();
    },
    searchbill() {
      this.bill_no = this.bill;

      console.log(this.bill, "bill");
      this.getItems();
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    async getPayment() {
      const data = await GET_PAYMENT();
      this.paymentModeList1 = data;
      const paymentModeList2 = this.paymentModeList1.map((item) => {
        return {
          text: item.payment_type,
          value: item.value,
        };
      });
      this.paymentModeList = paymentModeList2;
      if(this.paymentModeList){
              this.paymentModeList.push({
                text: "NA",
                value:1,
              });
            }
      
    },
    getSupplier() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "suppliers",
        })
        .then((data) => {
          _this.supplierLists = data;
          // console.log(_this.supplierList)
          //   const supplierList1 = _this.supplierLists.map((item) => {
          //     return {
          //       barcode: item.barcode + " - " + item.first_name,
          //       image: item.supplier_image?.url,
          //       value: item.id,
          //       name: item.first_name,
          //     };
          //   });
          //   _this.supplierList = supplierList1;
          // console.log(_this.supplierList)
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY ");
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YY hh:mm A");
    },
    formateTime(param) {
      return moment(param).format("hh:mm A");
    },
    getRowValue(row, data) {
      if (data.key === "profile_logo") {
        return this.getProfileImage(row[data.key]);
      } else {
        return row[data.key];
      }
    },
    activeInactive(data, id) {
      const _this = this;
      console.log(data);
      _this.$store
        .dispatch(PATCH, {
          url: `single-product/${data}/${id}`,
          data: {
            id: id,
            status: Number(data),
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
        });
    },
    getBgColor(value) {
      if (value && value == "all") {
        return "#90caf9";
      } else if (value && value == "active") {
        return "#6da16e";
      } else if (value && value == "inactive") {
        return "#c78a8a";
      }
    },
    getAvatarColor(value) {
      if (value && value == "all") {
        return "#0d47a1";
      } else if (value && value == "active") {
        return "green";
      } else if (value && value == "inactive") {
        return "red";
      }
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "purchase-order-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    //     routeToDetail(id) {
    //   this.$router.push({
    //     name: "purchase-order-detail",
    //     params: { id },
    //     query: { t: new Date().getTime() },
    //   });
    // },

    async getProducts() {
      const _this = this;

      this.pageLoading = true;
      try {
        const { rows, current_page, showingFrom, showingTo, totalrows, totalPages, status_list } =
          await All_PRPDUCTS(
            this.currentPage
            //this.per_page
          );

        this.productList = rows;
        console.log(this.productList, "totalPages");
        this.total = totalrows;
        this.status_list = status_list;
        this.totalPages = totalPages;
        console.log(this.status_list);

        this.currentPage = current_page;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.status_list = status_list;

        this.totalrows = totalrows;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async getItems(search = null) {
      try {
        // const form = {
        //     current_page: this.currentPage,
        //     search: this.listingSearch,
        //     bill_no: this.bill_no,
        // };
        // this.selectstatus = this.stauss

        this.pageLoading = true;
        console.log(this.stauss, "this.stauss");
        const {
          data,
          totalPages,
          showingFrom,
          showingTo,
          all,
          initial_paid,
          final_paid,
          initial_pending,
          final_pending,
          initial_pending_amount,
          all_amount,
          final_pending_amount,
          final_paid_amount,
          initial_paid_amount,
          initial_draft_amount,
          initial_draft,
          status_list,theads
        } = await GET_PO(
          this.bill_no,
          this.supplier_filter,
          this.paymentby,
          this.range,
          search,
          this.selectstatus,
          this.currentPage,
          this.status_filter
        );
        this.productList = data;
        console.log(this.purchaseOrder);
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.status_list = status_list;
        this.showingTo = showingTo;
        this.totalRows = all;
        this.initial_paids = initial_paid;
        this.paids = final_paid;
        this.pendings = initial_pending;
        this.final_pendings = final_pending;
        this.initial_paids_amount = initial_paid_amount;
        this.paids_amount = final_paid_amount;
        this.pendings_amount = initial_pending_amount;
        this.final_pendings_amount = final_pending_amount;
        this.initial_draft = initial_draft;
        this.initial_draft_amount = initial_draft_amount;
        this.all_amount = all_amount;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    checkAll(e) {
      console.log({ e });
    },
    dragStart(dragStartIndex) {
      this.dragStartIndexForProdctTableColumns = dragStartIndex;
    },
    dragOver(dragOverIndex) {
      this.dragOverIndexForProductTableColumns = dragOverIndex;
    },
    dragEnd() {
      const itemToReplace = this.productTableHeading[this.dragStartIndexForProdctTableColumns];
      const itemToReplaceIndex = this.dragStartIndexForProdctTableColumns;
      const whereToDropIndex = this.dragOverIndexForProductTableColumns;

      this.productTableHeading.splice(itemToReplaceIndex, 1);
      this.productTableHeading.splice(whereToDropIndex, 0, itemToReplace);

      this.dragStartIndexForProdctTableColumns = null;
      this.dragOverIndexForProductTableColumns = null;
    },
    mouseEvent(eventType) {
      const listItem = document.querySelector(".column-arrangement-list-item");
      if (eventType === "mousedown") {
        console.log({ eventType, listItem });
        listItem.classList.add("cursor-grabbing");
        listItem.classList.remove("cursor-grab");
      } else {
        console.log({ eventType, listItem });
        // listItem.setProperty('cursor', 'grab', 'important');

        listItem.classList.add("cursor-grab");
        listItem.classList.remove("cursor-grabbing");
      }
    },
    dataKey(key, value = null) {
      if (key.includes("(")) {
        if (key.includes(",")) {
          const _key = key.replace("(", "").replace(")", "").replace(" ", ",").toLocaleLowerCase();
          const arr = _key.split(",");
          return { main: arr[0], key: value ? Object.keys(value) : arr.slice(1) };
        } else {
          const _key = key.replace("(", "").replace(")", "").replace(" ", "_").toLocaleLowerCase();
          return _key;
        }
      } else {
        return key.toLocaleLowerCase();
      }
    },
    addOrder({ id }) {
      // this.customerId = id;
      // this.orderDialog = true;
      console.log({ id });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "purchase-order-detail",
        params: { id },
        query: { t: new Date().getTime(),page: this.currentPage},
      });
    },
    deleteConfirm({ uuid, purchase_order }) {
      this.deleteText = purchase_order;
      this.deleteEndpoint = `purchase-order/${uuid}`;
      this.deleteDialog = true;
    },
  },
  mounted() {
    let _this = this;
    
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getItems(argument);
    });
    SearchEventBus.$emit("search-template", true);
    this.getSupplier();
    this.getPayment();
    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Purchase Orders");
    this.getItems();
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
    SearchEventBus.$emit("search-template", false);
  },
  components: {
    DeleteTemplate,
    ValueTemplate,
    PageHeaderCounts,
    /*  SelectInput, */
    AutoCompleteInput,
    DateRangePicker,
  },

  computed: {
    ...mapGetters(["listingSearch"]),
    modifiedTableHeading() {
      const _tableHadings = this.productTableHeading.filter((heading) => heading.status);
      return _tableHadings;
    },
    dimentions() {
      const screenWidth = screen.width;
      const screenHeight = screen.height;
      return { height: screenHeight, width: screenWidth };
    },
  },
};
</script>

<style scoped>
.product-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.product-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.product-table-bottom {
  position: relative !important;
  bottom: 0% !important;
}

::-webkit-scrollbar {
  height: 5px !important;
}
.v-menu__content {
  top: 168px !important;
}
</style>
